@import "../../../assets/variables/variables.scss";

.footer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  background: #fff;
  font-family: 'Roboto', sans-serif;
  /*background: #f7f7f7;*/
  border-top: solid 1px rgba(0,0,0,.1);
  border-bottom: solid 4px map-get($theme-colors, 'blue');
  /*box-shadow: 0 -1px 5px #eaf0f6;*/
  /*box-shadow: 0px 9.03012px 27.0904px rgba(176, 190, 197, 0.32),
  0px 3.38629px 5.64383px rgba(176, 190, 197, 0.32);*/
  &-inner {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
    display: flex;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 2040px) {
      width: calc(100% - 40px);
      padding: 0 20px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
    &-logo {
      width: auto;
      @media only screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
    &-rec {
      width: auto;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: map-get($theme-colors, 'blue');
        text-decoration: none;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        text-align: center;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-info {
      width: auto;
      max-width: 400px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        margin: 0 0 5px 0;
        text-align: right;
        @media only screen and (max-width: 768px) {
          text-align: center;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.green {
  .footer {
    border-bottom: solid 4px map-get($theme-colors, 'green');
    &-inner {
      &-rec {
        a {
          color: map-get($theme-colors, 'green');
        }
      }
    }
  }
}
