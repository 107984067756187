@import "../../../assets/variables/variables.scss";

.map-box {
  width: 600px;
  min-width: 500px;
  background-color: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Roboto", sans-serif;
  //box-shadow: 0 9.03012px 27.0904px rgba(176, 190, 197, 0.32),
  //0 3.38629px 5.64383px rgba(176, 190, 197, 0.32);
  @media only screen and (max-width: 1500px) {
    width: 500px;
  }
  @media only screen and (max-width: 1300px) {
    width: 400px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    min-width: 0;
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    #map {
      min-width: 100%;
      height: 90vh;
      min-height: 500px;
      margin: 0;
      border-radius: 8px 0 0 0;
      @media only screen and (max-width: 992px) {
        max-height: 700px;
      }
      //border: solid 2px #f9f9f9;
      .leaflet-map-pane {
        .leaflet-overlay-pane {
          .leaflet-interactive {
            //stroke: #fff;
            //stroke-width: 1px;
            //fill: map-get($theme-colors, 'purple');
            //fill-opacity: 1;
          }
        }
      }
    }
    &-fixed {
      width: 180px;
      position: absolute;
      top: 95px;
      right: 10px;
      padding: 10px;
      z-index: 10000;
      background-color: rgba(255, 255, 255, 0.6);
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
          width: 100%;
          margin: 0;
          position: relative;
          font-size: 14px;
          &:after {
            content: "";
            position: absolute;
            bottom: -5px;
            width: 25px;
            height: 1px;
            background-color: #333;
            display: block;
          }
        }
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0;
          margin: 14px 0 0 0;
          li {
            width: calc(50% - 15px);
            margin-bottom: 10px;
            list-style: none;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 4px;
            cursor: pointer;
            background-color: map-get($theme-colors, "purple");
            border: solid 1px lighten(map-get($theme-colors, "blue"), 50%);
            transition: 0.3s ease-in-out;
            &.active,
            &:hover {
              background-color: map-get($theme-colors, "blue");
              color: #fff;
            }
          }
        }
        &-select {
          width: 100%;
          height: auto;
          margin: 14px 0 0 0;
          padding: 0;
          position: relative;
          display: flex;
          &:after {
            content: "";
            display: block;
            width: 24px;
            height: 18px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            right: 5px;
            pointer-events: none;
            background: url("../../../assets/images/arrow_drop_down.svg") center
            center no-repeat;
            background-size: 24px;
          }
          select {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 6px 8px;
            margin: 0;
            width: 100%;
            border: solid 1px lighten(map-get($theme-colors, "blue"), 50%);
            outline: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: map-get($theme-colors, "purple");
            appearance: none;
            position: relative;
            &::-ms-expand {
              display: none;
            }
            option {
              background-color: #fff;
            }
          }
        }
      }
    }
    &-legend {
      width: calc(100% - 20px);
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 10px;
      justify-content: flex-start;
      &-item {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0 30px 0 0;
        align-items: center;
        @media only screen and (max-width: 768px) {
          margin-right: 15px;
        }
        li {
          font-size: 13px;
          font-family: "Roboto", sans-serif;
          list-style: none;
          @media only screen and (max-width: 768px) {
            font-size: 12px;
          }
          &:nth-child(1) {
            span {
              width: 14px;
              height: 14px;
              border-radius: 4px;
              display: block;
              margin-right: 8px;
              @media only screen and (max-width: 768px) {
                width: 14px;
                height: 14px;
                margin-right: 7px;
              }
            }
          }
        }
        &:nth-child(1) {
          li {
            &:nth-child(1) {
              span {
                background-color: map-get($theme-colors, "gray");
              }
            }
          }
        }
        &:nth-child(2) {
          li {
            &:nth-child(1) {
              span {
                background-color: map-get($theme-colors, "purple");
              }
            }
          }
        }
        &:nth-child(3) {
          li {
            &:nth-child(1) {
              span {
                background-color: map-get($theme-colors, "blue");
              }
            }
          }
        }
      }
    }
  }

}
//Personal css for map label
.polygon-labels {
  background-color: transparent;
  font-style: italic;
  color: lighten(#776091, 10%);
  border: transparent;
  font-weight: bolder;
  box-shadow: none;
  overflow-wrap: break-word;
  width: fit-content;
}

.polygon-labels-selected {
  background-color: transparent;
  font-style: italic;
  color: #fff;
  border: transparent;
  font-weight: bold;
  box-shadow: none;
  overflow-wrap: break-word;
  width: fit-content;
}

.polygon-labels-region {
  font-size: 14px;
}

.polygon-labels-prefecture {
  font-size: 12px;
}

.polygon-labels-commune {
  font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .polygon-labels-region {
    font-size: 9px;
  }

  .polygon-labels-prefecture {
    font-size: 7px;
  }

  .polygon-labels-commune {
    font-size: 6px;
  }
}

// END personal css for map label
.green {
  .map-box {
    &-content {
      #map {
        .leaflet-map-pane {
          .leaflet-overlay-pane {
            .leaflet-interactive {
              //fill: lighten(map-get($theme-colors, 'green'), 40%);
              //fill-opacity: .8;
            }
          }
        }
      }
      &-fixed {
        &-item {
          ul {
            li {
              background-color: lighten(map-get($theme-colors, "green"), 60%);
              border: solid 1px lighten(map-get($theme-colors, "green"), 12%);
              &.active,
              &:hover {
                background-color: map-get($theme-colors, "green");
                color: #fff;
              }
            }
          }
          &-select {
            select {
              background-color: lighten(map-get($theme-colors, "green"), 60%);
              border: solid 1px lighten(map-get($theme-colors, "green"), 12%);
            }
          }
        }
      }
      &-legend {
        &-item {
          &:nth-child(1) {
            li {
              &:nth-child(1) {
                span {
                  background-color: map-get($theme-colors, "gray");
                }
              }
            }
          }
          &:nth-child(2) {
            li {
              &:nth-child(1) {
                span {
                  background-color: lighten(map-get($theme-colors, "green"), 60%);
                }
              }
            }
          }
          &:nth-child(3) {
            li {
              &:nth-child(1) {
                span {
                  background-color: lighten(map-get($theme-colors, "green"), 25%);
                }
              }
            }
          }
          &:nth-child(4) {
            li {
              &:nth-child(1) {
                span {
                  background-color: map-get($theme-colors, "green");
                }
              }
            }
          }
        }
      }
    }
  }
}
