/* Colors */
/* Green */
/* #156c52 */
/* Red */
/* #d21b33 */
/* Yellow */
/* #ffce15 */

@import "../assets/variables/variables.scss";

body {
  margin: 0;
  padding: 0;
}

body.open {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    a {
      text-decoration: none;
    }
  }
}

.page-wrapper {
  &-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 20px;
    button {
      cursor: pointer;
      background-color: black;
      border: none;
      color: #fff;
      padding: 7px 12px;
    }
  }
}

/* Custom checkbox */
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  & + label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: solid 2px #666;
    border-radius: 4px;
  }
  // Box hover
  /*&:hover + label:before {
    background: #006A63;
  }
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }*/
  // Box checked
  &:checked + label:before {
    /*background: #006A63;
    border-color: #006A63;*/
    background: map-get($theme-colors, 'purple');
    border-color: map-get($theme-colors, 'blue');
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 11px;
    background: map-get($theme-colors, 'blue');
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 map-get($theme-colors, 'blue'), 4px 0 0 map-get($theme-colors, 'blue'), 4px -2px 0 map-get($theme-colors, 'blue'), 4px -4px 0 map-get($theme-colors, 'blue'), 4px -6px 0 map-get($theme-colors, 'blue'), 4px -8px 0 map-get($theme-colors, 'blue');
    transform: rotate(45deg);
  }
}

/* Spinner box */

.spinner-box {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 15px;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
  &-inner {
    width: 24px;
    height: 24px;
    border: 3px solid #fff;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
    @media only screen and (max-width: 768px) {
      width: 18px;
      height: 18px;
    }
  }
}
