@import "../../assets/variables/variables.scss";

.timeline {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 20px 20px 30px;
  margin: 0;
  background: #ffffff;
  box-shadow: 0 2px 10px #ccc;
  border-radius: 10px;
  @media only screen and (max-width: 992px) {
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: 0;
    box-shadow: 0 2px 10px #ccc;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 30px);
    padding: 15px;
  }
  /* box-shadow: 0px 9.03012px 27.0904px rgba(176, 190, 197, 0.32),
     0px 3.38629px 5.64383px rgba(176, 190, 197, 0.32);*/
  h1 {
    width: 100%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
    color: #000000;
    margin: 10px 0 50px 0;
    position: relative;
    @media only screen and (max-width: 768px) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 70px;
      height: 3px;
      background-color: #333;
      display: block;
    }
  }
  .is-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    p {
      margin: 0;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
    }
    .spinner-box {
      width: 42px;
      height: 42px;
      margin-left: 0;
      margin-bottom: 10px;
      &-inner {
        width: 32px;
        height: 32px;
        border: 4px solid map-get($theme-colors, 'blue');
        border-top: 3px solid transparent;
      }
    }
  }
  &-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      &-table {
        width: 100%;
        display: flex;
        height: 223px;
        overflow: hidden;
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 1300px;
        transition: height 1s ease-in-out;
        &.show {
          height: auto;
          overflow: initial;
        }
        &-left {
          width: 16.66%;
          margin: 40px 0 0 0;
          ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            padding: 0;
            min-height: 60px;
            border-top: solid 1px #ededed;
            li {
              font-size: 16px;
              font-weight: normal;
              font-family: 'Roboto', sans-serif;
              list-style: none;
              margin-bottom: 0;
            }
          }
        }
        &-right {
          width: 83.34%;
          display: flex;
          flex-direction: row;
          .region-item {
            width: 20%;
            padding-right: 0;
          }
        }
      }
      &-read-more {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 20px 0;
        min-width: 1300px;
        border-top: solid 1px #ededed;
        button {
          cursor: pointer;
          background-color: map-get($theme-colors, 'blue');
          border: solid 1px map-get($theme-colors, 'blue');
          padding: 12px 20px;
          color: #fff;
          border-radius: 4px;
          font-size: 16px;
          font-family: 'Roboto', sans-serif;
          transition: all .3s ease-in-out;
          &.hide {
            display: none;
          }
          &:hover {
            background-color: transparent;
            color: map-get($theme-colors, 'blue');
          }
        }
      }
    }
    .carousel-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      &.show {
        height: auto;
        overflow: initial;
        .mobile-regions-wrapper {
          .rec-carousel {
            max-height: unset !important;
          }
        }
      }
      .mobile-regions-wrapper {
        width: 100%;
        .rec.rec-pagination {
          display: none;
        }
        .rec-item-wrapper {
          overflow: visible;
        }
        .rec-carousel {
          position: relative;
          margin: 10px 0;
          max-height: 290px !important;
        }
        .rec-carousel .rec.rec-arrow-left,
        .rec-carousel .rec.rec-arrow-right {
          width: 34px;
          height: 34px;
          min-width: 34px;
          min-height: 34px;
          line-height: 34px;
          position: absolute;
          z-index: 30;
          opacity: 1;
          font-size: 14px;
          background-color: map-get($theme-colors, 'blue');
          color: #fff;
          border: none;
          box-shadow: none;
          border-radius: 50%;
          &:disabled {
            background-color: #ccc;
            color: #333;
          }
        }
        .rec-carousel .rec.rec-arrow-left {
          left: 0;
          right: unset;
        }
        .rec-carousel .rec.rec-arrow-right {
          right: 0;
          left: unset;
        }
        .rec-slider {
          padding-left: 0;
          min-height: 140px;
        }
        .rec-slider-container {
          width: 100%;
          padding: 15px 0 0 0;
          margin: 0;
        }
      }
      &-read-more {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 20px 0;
        border-top: solid 1px #ededed;
        button {
          cursor: pointer;
          background-color: map-get($theme-colors, 'blue');
          border: solid 1px map-get($theme-colors, 'blue');
          padding: 12px 20px;
          color: #fff;
          border-radius: 4px;
          font-size: 16px;
          font-family: 'Roboto', sans-serif;
          transition: all .3s ease-in-out;
          &.hideMobile {
            display: none;
          }
          &:hover {
            background-color: transparent;
            color: map-get($theme-colors, 'blue');
          }
        }
      }
    }
  }
  &-legend {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border-top: solid 1px map-get($theme-colors, 'gray');
    &-title {
      text-align: left;
      h4 {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        line-height: 26px;
        margin: 20px 0 0 0;
      }
    }
    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 10px 0 0 0;
      &-item {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0 30px 0 0;
        align-items: center;
        li {
          font-size: 14px;
          font-family: 'Roboto', sans-serif;
          list-style: none;
          &:nth-child(1) {
            span {
              width: 18px;
              height: 18px;
              border-radius: 4px;
              display: block;
              margin-right: 10px;
            }
          }
        }
        &:nth-child(1) {
          li {
            &:nth-child(1) {
              span {
                background-color: map-get($theme-colors, 'gray');
              }
            }
          }
        }
        &:nth-child(2) {
          li {
            &:nth-child(1) {
              span {
                background-color: lighten(map-get($theme-colors, 'green'), 60%);
              }
            }
          }
        }
        &:nth-child(3) {
          li {
            &:nth-child(1) {
              span {
                background-color: map-get($theme-colors, 'green');
              }
            }
          }
        }
      }
    }
  }
}

.green {
  .timeline {
    .is-loading {
      .spinner-box {
        &-inner {
          border: 4px solid map-get($theme-colors, 'green');
          border-top: 3px solid transparent;
        }
      }
    }
    &-inner {
      &-wrapper {
        .is-loading {
          .spinner-box {
            &-inner {
              border-color: map-get($theme-colors, 'green');
              border-top: 3px solid transparent;
            }
          }
        }
        &-read-more {
          button {
            background-color: map-get($theme-colors, 'green');
            border: solid 1px map-get($theme-colors, 'green');
            &:hover {
              background-color: transparent;
              color: map-get($theme-colors, 'green');
            }
          }
        }
      }
    }
    .carousel-wrapper {
      .mobile-regions-wrapper {
        .rec-carousel .rec.rec-arrow-left,
        .rec-carousel .rec.rec-arrow-right {
          background-color: map-get($theme-colors, 'green');
        }
      }
      &-read-more {
        button {
          background-color: map-get($theme-colors, 'green');
          border: solid 1px map-get($theme-colors, 'green');
          &:hover {
            color: map-get($theme-colors, 'green');
          }
        }
      }
    }
  }
}
