$theme-colors: (
        'green' : #187C5E,
        'red' : #f44336,
        'yellow' : #ffce15,
        'gray': #ededed,
        'purple': #e0d6ff,
        'blue': #1d007d,
        'prise-blue': #006A9A,
        'prise-gray-light': #E1E3E0,
)
