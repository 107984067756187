@import "../../../assets/variables/variables.scss";

.analayse-container {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  &-sidebar {
    width: 300px;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.14), 0 3px 16px rgba(0, 0, 0, 0.12), 0 5px 6px rgba(0, 0, 0, 0.2);
    padding: 20px 16px;
    position: relative;
    z-index: 10;
    height: calc(100vh - 40px);
    overflow-y: auto;
    &-title {
      h2 {
        margin: 0 0 10px 0;
        font-size: 28px;
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 32px;
          margin: 0 10px 0 0;
        }
      }
    }
    &-content {
      p {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 10px 0;
      }
      &-list {
        width: 100%;
        margin: 20px 0 0 0;
        &-item {
          width: 100%;
          padding: 0;
          box-shadow: 0 10px 30px 0 rgba(28, 9, 80, .2);
          /*box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);*/
          border-radius: 8px;
          margin: 0 0 20px 0;
          overflow: hidden;
          position: relative;
          &:last-child {
            margin-bottom: 0;
          }
          &-link {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            width: calc(100% - 12px);
            height: calc(180px - 12px);
            padding: 6px;
            border-radius: 6px;
            background: #fff;
            position: relative;
            overflow: hidden;
            &:before {
              content: "";
              width: calc(100% - 12px);
              height: calc(100% - 12px);
              display: block;
              position: absolute;
              top: 6px;
              left: 6px;
              border-radius: 6px;
              pointer-events: none;
              z-index: 2;
              background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .6));
            }
            img {
              width: calc(100% - 12px);
              height: calc(100% - 12px);
              object-fit: cover;
              border-radius: 6px;
              position: absolute;
              top: 6px;
              left: 6px;
              z-index: 1;
            }
            &-placeholder {
              width: calc(100% - 20px);
              padding: 10px;
              background-color: map-get($theme-colors, "prise-blue");
              display: flex;
              flex-direction: row;
              justify-content: center;
              h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                color: #fff;
              }
            }
            &-text {
              width: calc(100% - 24px);
              height: calc(100% - 24px);
              padding: 12px;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
              z-index: 2;
              h5 {
                color: #fff;
                font-size: 16px;
                line-height: 22px;
                margin: 0;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
              }
              p {
                color: #fff;
                font-size: 12px;
                line-height: 16px;
                margin: 3px 0 0 0;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
              }
            }
          }
        }
      }
    }
  }
  &-content {
    width: calc(100% - 300px);
    .map-box {
      width: 100%;
      height: 100%;
      display: flex;
      #map {
        width: 100%;
        height: 100%;
      }
    }
  }
}