@import "../../../../assets/variables/variables.scss";

.education-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Montserrat", sans-serif;
  &-menu {
    width: 80px;
  }
  &-sidebar {
    width: 300px;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.14), 0 3px 16px rgba(0, 0, 0, 0.12), 0 5px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 16px;
    position: relative;
    z-index: 10;
    height: calc(100vh - 20px);
    overflow-y: auto;
    &-title {
      h2 {
        margin: 0 0 10px 0;
        font-size: 28px;
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 32px;
          margin: 0 10px 0 0;
        }
      }
    }
    p {
      font-size: 16px;
      margin: 0 0 10px 0;
    }
    &-list {
      width: 100%;
      margin: 20px 0 0 0;
      &-item {
        width: 100%;
        padding: 0;
        box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin: 0 0 20px 0;
        overflow: hidden;
        &:last-child {
          margin-bottom: 0;
        }
        &-placeholder {
          width: calc(100% - 20px);
          padding: 10px;
          background-color: map-get($theme-colors, "prise-blue");
          display: flex;
          flex-direction: row;
          justify-content: center;
          h4 {
            font-size: 14px;
            margin: 0;
            color: #fff;
          }
        }
        &-text {
          width: calc(100% - 24px);
          padding: 12px;
          h5 {
            font-size: 18px;
            margin: 0;
          }
          p {
            font-size: 14px;
            margin: 10px 0 0 0;
          }
        }
      }
    }
    &-filter {
      width: 100%;
      &-item {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .MuiFormControl-root {
          margin-top: 5px;
          .MuiFormLabel-root {
            &.Mui-focused,
            &.MuiFormLabel-filled {
              transform: translate(14px, -7px) scale(.86);
            }
            transform: translate(14px, 12px) scale(1);
            font-size: 14px;
          }
          .MuiInputBase-root {
            .MuiSelect-root {
              padding: 10px;
              font-size: 14px;
            }
          }
        }
        ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 0;
          margin: 20px 0 0 0;
          li {
            width: calc(50% - 10px);
            button {
              background-color: map-get($theme-colors, "prise-blue");
              font-family: "Montserrat", sans-serif;
              border: none;
              width: 100%;
              color: #fff;
              padding: 8px 12px;
              border-radius: 5px;
              outline: none;
              cursor: pointer;
            }
          }
        }
        &-checklist {
          width: calc(100% - 20px);
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
          &-item {
            width: 100%;
            margin: 0 0 15px 0;
            display: flex;
            flex-direction: column;
            &:last-child {
              margin-bottom: 0;
            }
            p {
              font-size: 14px;
              font-weight: 600;
              margin: 0 0 5px 0;
              font-family: "Montserrat", sans-serif;
            }
            .MuiFormControlLabel-root {
              margin-left: -5px;
              .MuiCheckbox-root {
                padding: 4px;
                .MuiSvgIcon-root {
                  width: 20px;
                  height: 20px;
                }
              }
              .MuiTypography-root {
                font-size: 14px;
                font-family: "Montserrat", sans-serif;
              }
            }
          }
        }
        &-quality {
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
          margin: 25px 0 0 0;
          p {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 5px 0;
            font-family: "Montserrat", sans-serif;
          }
        }
      }
    }
  }
  &-map {
    width: calc(100% - 380px);
    height: 100vh;
    background-color: #f2f2f2;
    #map {
      z-index: 18;
      height: 100% !important;
      border-radius: 0;
    }
  }
}