@import "../../../assets/variables/variables.scss";

.area-block {
  width: calc(100% - 600px);
  padding: 0;
  background-color: #fff;
  border-left: solid 1px map-get($theme-colors, 'gray');
  font-family: 'Roboto', sans-serif;
  position: relative;
  /*box-shadow: 0 9.03012px 27.0904px rgba(176, 190, 197, 0.32),
  0 3.38629px 5.64383px rgba(176, 190, 197, 0.32);*/
  @media only screen and (max-width: 1500px) {
    width: calc(100% - 500px);
  }
  @media only screen and (max-width: 1300px) {
    width: calc(100% - 400px);
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100vh;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10000000;
    transition: top .3s ease-in-out;
    &.open {
      top: 0;
    }
  }
  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    padding: 0;
  }
  &-select-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      max-height: 25%;
      max-width: 25%;
      margin-bottom: 20px;
      &.green {
        display: none;
      }
    }
    p {
      font-size: 20px;
    }
  }
  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      padding: 0;
      &-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 40px;
        margin: 0;
        overflow-x: auto;
        @media only screen and (max-width: 992px) {
          padding: 30px 30px;
        }
        @media only screen and (max-width: 768px) {
          padding: 30px 15px;
        }
        @media only screen and (max-width: 480px) {
          padding: 20px 15px;
        }
        //border-bottom: solid 1px map-get($theme-colors, 'gray');
        &-title {
          width: calc(100% - 100px);
          padding: 0;
          ul {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li {
              font-size: 24px;
              font-weight: 600;
              list-style: none;
              margin-right: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              font-family: 'Poppins', sans-serif;
              @media only screen and (max-width: 992px) {
                font-size: 20px;
              }
              @media only screen and (max-width: 768px) {
                font-size: 18px;
              }
              @media only screen and (max-width: 480px) {
                font-size: 16px;
              }
              &:after {
                content: " / ";
                display: block;
                position: relative;
                top: 0;
                left: 0;
                font-size: 18px;
                @media only screen and (max-width: 768px) {
                  font-size: 16px;
                }
                @media only screen and (max-width: 480px) {
                  font-size: 14px;
                }
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        &-close-window {
          width: 100px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          p {
            font-size: 14px;
            margin: 0;
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
          img {
            margin-left: 6px;
          }
        }
      }
    }
    &-body {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      padding: 0 40px;
      @media only screen and (max-width: 992px) {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
      @media only screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0 20px;
      }
      @media only screen and (max-width: 768px) {
        width: calc(100% - 30px);
        padding: 0 15px;
      }
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 0 30px 0;
        @media only screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
        h3 {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin: 0;
          @media only screen and (max-width: 992px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        ul {
          width: 100%;
          margin: 20px 0 0 0;
          display: flex;
          flex-direction: row;
          @media only screen and (max-width: 768px) {
            margin-top: 15px;
          }
          @media only screen and (max-width: 480px) {
            margin-top: 10px;
          }
          li {
            margin: 0 5px 0 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &-accessibility,
        &-progress {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 30px 0 0 0;
          @media only screen and (max-width: 768px) {
            margin-top: 20px;
          }
          .accessibility-content {
            width: calc(33.33% - 30px);
            padding-right: 30px;
            justify-content: flex-start;
            @media only screen and (max-width: 768px) {
              width: calc(50% - 10px);
              &:nth-child(2n+1) {
                padding: 0 10px 0 0;
              }
              &:nth-child(2n) {
                padding: 0 0 0 10px;
              }
            }
            @media only screen and (max-width: 480px) {
              width: 100%;
              align-items: center;
              &:nth-child(2n+1) {
                padding: 0;
              }
              &:nth-child(2n) {
                padding: 0;
              }
            }
          }
        }
      }
    }
    &-legend {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 15px 0;
      justify-content: flex-start;
      border-top: solid 1px #ccc;
      @media only screen and (max-width: 992px) {
        width: calc(100% - 60px);
        padding: 15px 30px;
      }
      @media only screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 15px 20px;
      }
      @media only screen and (max-width: 480px) {
        width: calc(100% - 30px);
        padding: 15px;
      }
      p {
        margin: 0;
        text-decoration: underline;
        @media only screen and (max-width: 768px) {
          font-size: 13px;
        }
      }
      &-block {
        width: 100%;
        display: flex;
        margin: 15px 0 0 0;
        flex-direction: row;
        &-item {
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0 30px 0 0;
          align-items: center;
          @media only screen and (max-width: 768px) {
            margin-right: 15px;
          }
          li {
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            list-style: none;
            @media only screen and (max-width: 768px) {
              font-size: 12px;
            }
            &:nth-child(1) {
              span {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                display: block;
                margin-right: 10px;
                @media only screen and (max-width: 768px) {
                  width: 14px;
                  height: 14px;
                  margin-right: 7px;
                }
              }
            }
          }
          &:nth-child(1) {
            li {
              &:nth-child(1) {
                span {
                  background-color: map-get($theme-colors, 'gray');
                }
              }
            }
          }
          &:nth-child(2) {
            li {
              &:nth-child(1) {
                span {
                  background-color: map-get($theme-colors, 'purple');
                }
              }
            }
          }
          &:nth-child(3) {
            li {
              &:nth-child(1) {
                span {
                  background-color: map-get($theme-colors, 'blue');
                }
              }
            }
          }
        }
      }
    }
  }
}

.green {
  .area-block {
    &-select-area {
      img {
        &.purple {
          display: none;
        }
        &.green {
          display: block;
        }
      }
    }
    &-wrapper {
      &-legend {
        &-block {
          &-item {
            &:nth-child(1) {
              li {
                &:nth-child(1) {
                  span {
                    background-color: map-get($theme-colors, 'gray');
                  }
                }
              }
            }
            &:nth-child(2) {
              li {
                &:nth-child(1) {
                  span {
                    background-color: lighten(map-get($theme-colors, 'green'), 60%);
                  }
                }
              }
            }
            &:nth-child(3) {
              li {
                &:nth-child(1) {
                  span {
                    background-color: lighten(map-get($theme-colors, 'green'), 25%);
                  }
                }
              }
            }
            &:nth-child(4) {
              li {
                &:nth-child(1) {
                  span {
                    background-color: map-get($theme-colors, 'green');
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
