@import "../../../assets/variables/variables.scss";

.project-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  &-inner {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    &-header {
      width: 100%;
      min-height: 220px;
      background-color: map-get($theme-colors, "green");
      position: relative;
      margin-bottom: 100px;
      @media only screen and (max-width: 768px) {
        min-height: 180px;
        margin-bottom: 80px;
      }
      @media only screen and (max-width: 480px) {
        min-height: 150px;
      }
      img {
        position: absolute;
        left: 50%;
        bottom: -45px;
      }
    }
    &-body {
      width: calc(100% - 60px);
      padding: 0 30px;
      margin: 0 auto 80px auto;
      display: flex;
      max-width: 1200px;
      flex-direction: column;
      //box-shadow: 0 2px 10px #ccc;
      border-radius: 10px;
      @media only screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0 20px;
      }
      h1 {
        margin: 0 0 20px 0;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
        font-size: 28px;
        line-height: 34px;
        text-transform: uppercase;
        text-align: center;
        @media only screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
      h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
        color: #333;
        margin: 0 0 15px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
