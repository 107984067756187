@import "./assets/variables/variables.scss";

.cartes-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    font-family: "Poppins", sans-serif;
    overflow: hidden;
    &-sidebar {
        width: 320px;
        min-width: 320px;
        position: relative;
        left: 0;
        height: calc(100vh - 70px);
        padding: 0;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 27px 0 rgba(214, 231, 233, 0.52);
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: #06613b;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &.hide {
            width: 0;
            min-width: 0;
            padding: 0;
            left: -1px;
            /*.cartes-wrapper-sidebar-closer button svg {
                transform: rotateX(0deg);
            }*/
        }
        &-inner {
            width: calc(100% - 30px);
            display: flex;
            height: 100%;
            padding: 10px 15px;
            overflow-y: auto;
            flex-direction: column;
            &-closer {
                width: 40px;
                height: 40px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 50px;
                right: -40px;
                padding: 0;
                border-radius: 0 4px 4px 0;
                background-color: #06613b;
                z-index: 20;
                /* box-shadow: 15px 0 27px 0 rgba(214, 231, 233, 0.52); */
                button {
                    padding: 0;
                    border-radius: 0;
                    width: 100%;
                    min-width: 40px;
                    min-height: 40px;
                    &:hover {
                        background-color: transparent;
                    }
                    svg {
                        font-size: 20px;
                        color: #fff;
                    }
                }
            }
            &-title {
                width: 100%;
                padding: 0;
                color: #fff;
                font-size: 22px;
            }
            &-menu {
                width: 100%;
                ul {
                    display: flex;
                    flex-direction: column;
                    li {
                        a {
                            display: flex;
                            width: calc(100% - 60px);
                            align-items: center;
                            padding: 15px 40px 15px 20px;
                            color: #fff;
                            font-size: 15px;
                            position: relative;
                            border-radius: 5px;
                            background-color: map-get($theme-colors, 'green');
                            margin: 0 0 8px 0;
                            svg {
                                margin-right: 10px;
                            }
                            span {
                                display: block;
                                &.text {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                }
                                &.plus {
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    display: flex;
                                }
                            }
                        }
                        .accordion-wrapper {
                            border-radius: 5px;
                            overflow: hidden;
                            margin: 0 0 8px 0;
                            .MuiPaper-root {
                                box-shadow: none;
                                margin-bottom: 15px;
                                overflow: hidden;
                                border-radius: 5px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                > .MuiButtonBase-root {
                                    min-height: initial;
                                    /* border-bottom: solid 1px #ccc; */
                                    background-color: map-get($theme-colors, 'green');
                                    border-top-left-radius: 5px;
                                    border-top-right-radius: 5px;
                                    .MuiAccordionSummary-expandIconWrapper {
                                        svg {
                                            color: #fff;
                                        }
                                    }
                                    .MuiAccordionSummary-content {
                                        margin: 0;
                                        p {
                                            font-size: 15px;
                                            line-height: 20px;
                                            font-family: "Poppins", sans-serif;
                                            width: 100%;
                                            padding: 15px 0;
                                            display: flex;
                                            flex-direction: row;
                                            color: #fff;
                                            svg {
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }
                                > .MuiCollapse-root {
                                    .accordion-details-content {
                                        margin-bottom: 15px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                            .MuiAccordionDetails-root {
                                background-color: lighten(map-get($theme-colors, "green"), 50%);
                                padding: 20px;
                                display: flex;
                                flex-direction: column;
                                .accordion-details-title {
                                    width: 100%;
                                    margin: 0 0 5px 0;
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                                .accordion-details-content {
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    .MuiFormControlLabel-root {
                                        margin: 0;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        .MuiCheckbox-root.Mui-checked {
                                            color: map-get($theme-colors, "green");
                                        }
                                        .MuiTypography-root {
                                            padding: 0 0 0 5px;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-map {
        width: 100%;
        position: relative;
        display: flex;
        /*height: calc(100vh - 70px) !important;*/
        &.full {
            width: 100%;
        }
        &-echelle {
            width: 180px;
            position: absolute;
            right: 10px;
            top: 70px;
            padding: 0;
            z-index: 20;
            border-radius: 4px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.6);
            border: 2px solid rgba(0, 0, 0, 0.2);
            .MuiPaper-root {
                box-shadow: none;
                > .MuiButtonBase-root {
                    border-radius: 5px;
                    padding: 3px 8px 3px 10px;
                    min-height: initial;
                    background-color: transparent;
                    .MuiAccordionSummary-expandIconWrapper {
                        svg {
                            color: #000;
                        }
                    }
                    .MuiAccordionSummary-content {
                        margin: 0;
                        p {
                            font-size: 14px;
                            font-family: "Poppins", sans-serif;
                            width: 100%;
                            padding: 5px 0;
                            display: flex;
                            flex-direction: row;
                            color: #000;
                            svg {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .MuiAccordionDetails-root {
                background-color: transparent;
                padding: 5px 10px 10px 10px;
                .accordion-details-list {
                    width: 160px;
                    display: flex;
                    flex-direction: column;
                    &-item {
                        width: 100%;
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .MuiAutocomplete-root {
                            &.MuiAutocomplete-hasClearIcon,
                            &.Mui-focused {
                                .MuiFormControl-root {
                                    .MuiInputLabel-root {
                                        transform: translate(14px, -9px) scale(0.75);
                                        font-size: 14px;
                                    }
                                }
                            }
                            .MuiFormControl-root {
                                .MuiInputLabel-root {
                                    transform: translate(14px, 8px) scale(1);
                                    font-size: 14px;
                                }
                            }
                            .MuiOutlinedInput-root {
                                padding: 0 0 0 5px;
                                input {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-legend {
            width: 180px;
            height: auto;
            position: absolute;
            background-color: rgba(255, 255, 255, 1);
            border: 2px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            bottom: 40px;
            right: 10px;
            padding: 0;
            z-index: 20;
            &-title {
                width: calc(100% - 24px);
                height: auto;
                padding: 8px 12px 6px 12px;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
                h3 {
                    margin: 0;
                    font-size: 17px;
                    font-weight: 500;
                }
            }
            .MuiPaper-root {
                box-shadow: none;
                > .MuiButtonBase-root {
                    border-radius: 5px;
                    padding: 3px 8px 3px 10px;
                    min-height: initial;
                    background-color: transparent;
                    .MuiAccordionSummary-expandIconWrapper {
                        svg {
                            color: #000;
                        }
                    }
                    .MuiAccordionSummary-content {
                        margin: 0;
                        p {
                            font-size: 14px;
                            font-family: "Poppins", sans-serif;
                            width: 100%;
                            padding: 5px 0;
                            display: flex;
                            flex-direction: row;
                            color: #000;
                            svg {
                                margin-right: 0;
                            }
                        }
                    }
                }
                > .MuiCollapse-root {
                    border-top: solid 1px rgba(0, 0, 0, 0.2);
                    .MuiAccordionDetails-root {
                        padding: 0;
                    }
                }
            }
            &-list {
                width: calc(100% - 20px);
                height: 250px;
                margin: 0;
                overflow-y: auto;
                padding: 10px;
                &-header {
                    h3 {
                        font-size: 13px;
                        line-height: 16px;
                        font-weight: 500;
                        margin: 0;
                    }
                }
                &-content {
                    width: 100%;
                    padding: 10px 0;
                    ul {
                        display: flex;
                        flex-direction: row;
                        margin: 0 0 7px 0;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        li {
                            color: #000;
                            font-size: 11px;
                            line-height: 16px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 0;
                            &.rectangle {
                                span {
                                    width: 26px !important;
                                    height: 18px !important;
                                    border-radius: 0;
                                    border: none !important;
                                }
                            }
                            &.line {
                                span {
                                    width: 40px;
                                    height: 2px;
                                    border-radius: 0;
                                    border: none;
                                }
                            }
                            span {
                                width: 12px;
                                height: 12px;
                                display: block;
                                border-radius: 50%;
                                border-width: 2px;
                                border-style: solid;
                                margin: 0 10px 0 0;
                            }
                            img {
                                width: 18px;
                                height: auto;
                                margin: 0 8px 0 0;
                            }
                        }
                    }
                }
            }
        }
        .map-box {
            min-width: 100%;
            height: 100%;
            #map {
                z-index: 18;
                height: 100% !important;
                border-radius: 0;
            }
        }
    }
}
