.new-project-container {
  width: 100%;
  position: relative;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: row;
  z-index: 10;
  &-menu {
    width: 80px;
  }
  &-content {
    height: 100vh;
    width: calc(100% - 80px);
  }
}