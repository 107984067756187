@import "../../../../assets/variables/variables.scss";

.water-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  &-menu {
    width: 80px;
  }
  &-sidebar {
    width: calc(332px - 32px) !important;
    min-width: 300px;
    box-shadow: 0 9px 12px rgba(0, 0, 0, 0.14), 0 3px 16px rgba(0, 0, 0, 0.12), 0 5px 6px rgba(0, 0, 0, 0.2);
    padding: 20px 16px;
    position: relative;
    z-index: 10;
    height: calc(100vh - 40px);
    overflow-y: auto;
    &-title {
      h2 {
        margin: 0 0 10px 0;
        font-size: 28px;
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 32px;
          margin: 0 10px 0 0;
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 10px 0;
    }
    &-list {
      width: 100%;
      margin: 20px 0 0 0;
      &-item {
        width: calc(100% - 12px);
        height: calc(180px - 12px);
        padding: 6px;
        border-radius: 6px;
        box-shadow: 0 10px 30px 0 rgba(28, 9, 80, .2);
        margin: 0 0 20px 0;
        overflow: hidden;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: "";
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          display: block;
          position: absolute;
          top: 6px;
          left: 6px;
          border-radius: 6px;
          pointer-events: none;
          z-index: 2;
          background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .6));
        }
        img {
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          object-fit: cover;
          border-radius: 6px;
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 1;
        }
        &-placeholder {
          width: calc(100% - 20px);
          padding: 10px;
          background-color: map-get($theme-colors, "prise-blue");
          display: flex;
          flex-direction: row;
          justify-content: center;
          h4 {
            font-size: 14px;
            margin: 0;
            color: #fff;
          }
        }
        &-text {
          width: calc(100% - 24px);
          height: calc(100% - 24px);
          padding: 12px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          z-index: 2;
          h5 {
            color: #fff;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
          }
          p {
            color: #fff;
            font-size: 12px;
            line-height: 16px;
            margin: 3px 0 0 0;
          }
        }
      }
    }
    &-filter {
      width: 100%;
      &-item {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .MuiFormControl-root {
          margin-top: 5px;
          .MuiFormLabel-root {
            &.Mui-focused,
            &.MuiFormLabel-filled {
              transform: translate(14px, -7px) scale(.86);
            }
            transform: translate(14px, 12px) scale(1);
            font-size: 14px;
          }
          .MuiInputBase-root {
            .MuiSelect-root {
              padding: 10px;
              font-size: 14px;
            }
          }
        }
        ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 0;
          margin: 20px 0 0 0;
          li {
            width: calc(50% - 10px);
            button {
              background-color: map-get($theme-colors, "prise-blue");
              font-family: "Montserrat", sans-serif;
              border: none;
              width: 100%;
              color: #fff;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              padding: 10px 12px;
              border-radius: 5px;
              outline: none;
              cursor: pointer;
              &.selected {
                background-color: lighten(map-get($theme-colors, "prise-blue"), 12%);
              }
            }
          }
        }
        &-checklist {
          width: calc(100% - 20px);
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
          &.hide {
            display: none;
          }
          &-item {
            width: 100%;
            margin: 0 0 15px 0;
            display: flex;
            flex-direction: column;
            &:last-child {
              margin-bottom: 0;
            }
            p {
              font-size: 14px;
              font-weight: 600;
              margin: 0 0 5px 0;
              font-family: "Montserrat", sans-serif;
            }
            ul.canvas-wrapper {
              width: 100%;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 3px 0 0 0;
              li {
                list-style: none;
                &:first-child {
                  width: 20px;
                }
                &:last-child {
                  width: calc(100% - 20px);
                  padding: 0 0 0 10px;
                }
                span {
                  height: 100%;
                  display: block;
                  &.polygon {
                    width: 20px;
                    height: 20px;
                    background: #C4C4C4;
                  }
                  &.circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #002115;
                  }
                  &.text {
                    font-size: 13px;
                  }
                }
              }
            }
            .MuiFormControlLabel-root {
              margin-left: -5px;
              .MuiCheckbox-root {
                padding: 4px;
                .MuiSvgIcon-root {
                  width: 20px;
                  height: 20px;
                }
              }
              .MuiTypography-root {
                font-size: 13px;
                font-family: "Montserrat", sans-serif;
              }
            }
          }
        }
        &-indicator {
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
          margin: 25px 0 0 0;
          &.hide {
            display: none;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 5px 0;
            font-family: "Montserrat", sans-serif;
          }
          .MuiFormControl-root {
            .radio-group-item {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 0 7px 0;
              .MuiFormControlLabel-root {
                width: calc(100% - 60px);
                .MuiRadio-root {
                  padding: 4px;
                  margin: 0 5px;
                  .MuiSvgIcon-root {
                    width: 20px;
                    height: 20px;
                  }
                }
                .MuiTypography-root {
                  font-size: 13px;
                  line-height: 16px;
                  font-family: "Montserrat", sans-serif;
                }
              }
              .indicator-color {
                display: flex;
                flex-direction: row;
                width: 50px;
                height: 20px;
                span {
                  display: block;
                  width: 10px;
                  height: 100%;
                  background: #fff;
                  &:nth-child(1) {
                    background: rgba(215, 25, 28, 1);
                  }
                  &:nth-child(2) {
                    background: rgba(253, 174, 97, 1);
                  }
                  &:nth-child(3) {
                    background: rgba(255, 255, 191, 1);
                  }
                  &:nth-child(4) {
                    background: rgba(167, 218, 160, 1);
                  }
                  &:nth-child(5) {
                    background: rgba(104, 160, 100, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-map {
    width: calc(100% - 380px) !important;
    position: relative;
    display: flex;
    height: 100vh !important;
  }
}