@import "../../../assets/variables/variables.scss";

.side-menu-content {
  width: 80px;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  position: relative;
  box-shadow: 0 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    li {
      width: 100%;
      margin: 0 0 30px 0;
      &:last-child {
        margin-bottom: 0;
      }
      &.home {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        a {
          width: 50px;
          min-width: 50px;
          max-width: 50px;
          border: none;
          height: 50px;
          min-height: 50px;
          padding: 0;
          border-radius: 50%;
          box-shadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: map-get($theme-colors, "prise-blue");
          color: #fff;
          &.active {
            border-left: none;
          }
          img {
            margin: 0;
          }
        }
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        &.disable-link {
          cursor: default;
          pointer-events: none;
        }
        &.active {
          border-left: solid 4px map-get($theme-colors, "prise-blue");
          .link-text {
            color: map-get($theme-colors, "prise-blue");
          }
        }
        img {
          width: 24px;
          margin: 0 0 5px 0;
        }
        .link-text {
          display: block;
          text-align: center;
          font-size: 13px;
          font-family: "Montserrat", sans-serif;
          color: map-get($theme-colors, "prise-gray-light");
        }
      }
    }
  }
}