@import "../../../assets/variables/variables.scss";
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    margin: 0;
    background: #ffffff;
    box-shadow: 0 1px 5px #ccc;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    z-index: 100;
    /* Shadow */
    /*box-shadow: 0 9.03012px 27.0904px rgba(176, 190, 197, 0.32),
    0 3.38629px 5.64383px rgba(176, 190, 197, 0.32);*/
    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000000;
        .nav-menu {
            top: 63px;
        }
    }
    &-navbar {
        width: 100%;
        margin: 0;
        padding: 0;
        &-inner {
            width: 100%;
            max-width: 2000px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            @media only screen and (max-width: 2400px) {
                width: calc(100% - 40px);
                padding: 0 20px;
            }
            @media only screen and (max-width: 480px) {
                width: calc(100% - 30px);
                padding: 0 15px;
            }
            &-left {
                display: flex;
                flex-direction: row;
                align-items: center;
                &-logo {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: 992px) {
                        min-height: 63px;
                    }
                    img {
                        width: 40px;
                        height: auto;
                        @media only screen and (max-width: 992px) {
                            width: 34px;
                        }
                    }
                }
                &-title {
                    display: flex;
                    flex-direction: column;
                    h3 {
                        font-size: 26px;
                        line-height: 28px;
                        margin: 0 0 5px 0;
                        font-family: 'Poppins', sans-serif;
                    }
                    p {
                        font-family: 'Roboto', sans-serif;
                        font-size: 16px;
                        margin: 0;
                    }
                }
            }
            &-right {
                display: flex;
                ul {
                    width: auto;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0;
                    margin: 0;
                    @media only screen and (max-width: 992px) {
                        width: calc(100% - 40px);
                        height: 100vh;
                        position: absolute;
                        top: 63px;
                        left: -110%;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        overflow: hidden;
                        padding: 20px;
                        z-index: 1000000000;
                        flex-direction: column;
                        background: map-get($theme-colors, 'blue');
                        &.active {
                            left: 0;
                            li {
                                width: 100%;
                                display: flex;
                                margin: 0;
                                border-bottom: solid 1px #fff;
                                a {
                                    width: calc(100% - 40px);
                                    padding: 0 20px;
                                    color: rgba(255, 255, 255, .7);
                                    -webkit-transition: .3s ease-in-out;
                                    -moz-transition: .3s ease-in-out;
                                    transition: .3s ease-in-out;
                                    @media only screen and (max-width: 992px) {
                                        width: 100%;
                                        padding: 0;
                                    }
                                    &.active {
                                        color: #fff;
                                    }
                                    &:hover {
                                        color: #fff;
                                    }
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    span {
                        display: flex;
                        flex-direction: row;
                        margin: 0 20px;
                        @media only screen and (max-width: 992px) {
                            width: 100%;
                            flex-direction: column;
                            margin: 0;
                        }
                    }
                    li {
                        list-style: none;
                        margin: 0 20px;
                        display: flex;
                        align-items: center;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        a,
                        button {
                            width: auto;
                            min-height: 50px;
                            display: inline-flex;
                            align-items: center;
                            font-size: 16px;
                            padding: 10px 0;
                            border-radius: 0;
                            text-decoration: none;
                            color: #000000;
                            font-weight: 500;
                            font-family: 'Poppins', sans-serif;
                            transition: all .3s ease-in-out;
                            background-color: transparent;
                            position: relative;
                            &:after {
                                content: "";
                                display: block;
                                width: 100%;
                                height: 4px;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                background-color: transparent;
                                transition: all .3s ease-in-out;
                            }
                            &.active,
                            &:hover {
                                color: map-get($theme-colors, 'blue');
                                &:after {
                                    background-color: map-get($theme-colors, 'blue');
                                    color: map-get($theme-colors, 'blue');
                                }
                            }
                        }
                        button {
                            min-height: 70px;
                            text-transform: none;
                            &:hover {
                                background-color: transparent;
                            }
                        }
                        &.logout {
                            a {
                                min-height: auto;
                                height: auto;
                                border-radius: 5px;
                                display: block;
                                color: map-get($theme-colors, 'blue');
                                border: solid 1px map-get($theme-colors, 'blue');
                                background-color: transparent;
                                padding: 10px 20px;
                                @media only screen and (max-width: 992px) {
                                    color: #fff;
                                    padding: 13px 0;
                                    border: none;
                                    color: rgba(255, 255, 255, .7);
                                }
                                &:after {
                                    display: none;
                                }
                                &:hover {
                                    background-color: map-get($theme-colors, 'blue');
                                    color: #fff;
                                    @media only screen and (max-width: 992px) {}
                                }
                            }
                        }
                    }
                }
                .nav-icon {
                    display: none;
                    @media only screen and (max-width: 992px) {
                        display: flex;
                        width: 42px;
                        height: 34px;
                        align-items: center;
                        margin: 0;
                        padding: 0;
                        justify-content: center;
                        cursor: pointer;
                        /* Hamburger side-menu */
                        #menu-button {
                            width: 42px;
                            height: 32px;
                            margin: 0;
                            border: none;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            -webkit-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -o-transform: rotate(0deg);
                            transform: rotate(0deg);
                            -webkit-transition: .5s ease-in-out;
                            -moz-transition: .5s ease-in-out;
                            -o-transition: .5s ease-in-out;
                            transition: .5s ease-in-out;
                            cursor: pointer;
                            outline: none;
                            &:not(.collapsed) span:nth-child(1),
                            &:not(.collapsed) span:nth-child(6) {
                                -webkit-transform: rotate(45deg);
                                -moz-transform: rotate(45deg);
                                -o-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                            &:not(.collapsed) span:nth-child(2),
                            &:not(.collapsed) span:nth-child(5) {
                                -webkit-transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                            }
                            &:not(.collapsed) span:nth-child(1) {
                                left: 4px;
                                top: 10px;
                            }
                            &:not(.collapsed) span:nth-child(2) {
                                left: calc(50% - 4px);
                                top: 10px;
                            }
                            &:not(.collapsed) span:nth-child(3) {
                                left: -50%;
                                opacity: 0;
                            }
                            &:not(.collapsed) span:nth-child(4) {
                                left: 100%;
                                opacity: 0;
                            }
                            &:not(.collapsed) span:nth-child(5) {
                                left: 4px;
                                top: 22px;
                            }
                            &:not(.collapsed) span:nth-child(6) {
                                left: calc(50% - 4px);
                                top: 22px;
                            }
                            span {
                                display: block;
                                position: absolute;
                                height: 2px;
                                min-height: 2px;
                                max-height: 2px;
                                width: 50%;
                                background: map-get($theme-colors, 'blue');
                                opacity: 1;
                                -webkit-transform: rotate(0deg);
                                -moz-transform: rotate(0deg);
                                -o-transform: rotate(0deg);
                                transform: rotate(0deg);
                                -webkit-transition: .25s ease-in-out;
                                -moz-transition: .25s ease-in-out;
                                -o-transition: .25s ease-in-out;
                                transition: .25s ease-in-out;
                                &:nth-child(even) {
                                    left: 50%;
                                }
                                &:nth-child(odd) {
                                    left: 0;
                                }
                                &:nth-child(1),
                                &:nth-child(2) {
                                    top: 0;
                                }
                                &:nth-child(3),
                                &:nth-child(4) {
                                    top: 14px;
                                }
                                &:nth-child(5),
                                &:nth-child(6) {
                                    top: 28px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.green {
    .header {
        &-navbar {
            &-inner {
                &-right {
                    ul {
                        @media only screen and (max-width: 992px) {
                            background: map-get($theme-colors, 'green');
                        }
                        li {
                            a,
                            button {
                                &.active,
                                &:hover {
                                    color: map-get($theme-colors, 'green');
                                    @media only screen and (max-width: 992px) {
                                        color: #fff;
                                    }
                                    &:after {
                                        background-color: map-get($theme-colors, 'green');
                                        color: map-get($theme-colors, 'green');
                                    }
                                }
                            }
                            &.logout {
                                a {
                                    color: map-get($theme-colors, 'green');
                                    border: solid 1px map-get($theme-colors, 'green');
                                    @media only screen and (max-width: 992px) {
                                        color: #fff;
                                        border: none;
                                    }
                                    &:hover {
                                        background-color: map-get($theme-colors, 'green');
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .nav-icon {
                        #menu-button {
                            span {
                                background-color: map-get($theme-colors, 'green');
                            }
                        }
                    }
                }
            }
        }
    }
}

#cartes-menu {
    .MuiPaper-root {
        .MuiMenu-list {
            .MuiMenuItem-root {
                padding: 0;
                a {
                    padding: 8px 16px;
                    text-decoration: none;
                    color: #000000;
                    &:hover {
                        color: map-get($theme-colors, 'green');
                    }
                }
            }
        }
    }
}