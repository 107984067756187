@import '../../../assets/variables/variables.scss';

.region-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-title {
    h2 {
      margin: 0;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      line-height: 20px;
      text-align: center;
      font-weight: normal;
    }
  }
  &-percent {
    margin: 20px 0 0 0;
    .progress {
      div {
        margin-bottom: 20px;
        background-color: map-get($theme-colors, 'gray') !important;
        div {
          span {
            font-family: 'Roboto', sans-serif;
            font-size: 11px !important;
            font-weight: normal !important;
          }
        }
      }
      &.start {
        div {
          background-color: map-get($theme-colors, 'purple') !important;
          div {
            background-color: map-get($theme-colors, 'blue') !important;
          }
        }
      }
    }
  }
}

.green {
  .region-item {
    &-percent {
      .progress {
        &.start {
          div {
            background-color:  lighten(map-get($theme-colors, 'green'), 60%) !important;
            div {
              background-color: map-get($theme-colors, 'green') !important;
            }
          }
        }
      }
    }
  }
}
